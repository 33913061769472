import saveDataToStorage from "../storage/saveDataToStorage"

const saveCredentialsToStorage = ({ email = "", password = "", expirationTime = "", authToken = "" }) => {

    saveDataToStorage({ key: "email", value: email })
    saveDataToStorage({ key: "password", value: password })
    saveDataToStorage({ key: "expirationTime", value: expirationTime })
    saveDataToStorage({ key: "authToken", value: authToken })

}

export default saveCredentialsToStorage