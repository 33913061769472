import { configureStore } from "@reduxjs/toolkit"

import authReducers from "./slices/authSlice"
import uiReducers from "./slices/uiSlice"
import feedbackReducers from "./slices/feedbackSlice"

const store = configureStore({
    reducer: {
        auth: authReducers,
        ui: uiReducers,
        feedback: feedbackReducers,
    }
})

export default store