import React from "react"
import { Link } from "react-router-dom"

import { SCREEN_WIDTH } from "../../config/constants"

import Logo from "../ui/Logo"
import BoxTitle from "../typography/BoxTitle"
import EmailButton from "../buttons/EmailButton"
import Paragraph from "../typography/Paragraph"

const Footer = () => {

    const alignment = SCREEN_WIDTH <= 768 ? "text-center" : "text-start"

    const year = new Date().getFullYear()

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <footer className="bg-primary">
            <div className="d-lg-flex align-items-start justify-content-evenly p-5">

                <Logo white />

                <div className="mt-4">
                    <BoxTitle className="text-white mb-2">Contact Us:</BoxTitle>
                    <EmailButton className={`d-grid ${alignment}`} color="white" />
                </div>

                <div className="mt-4">
                    <BoxTitle className="text-white mb-2">Sign In:</BoxTitle>
                    <Link className={`text-white d-grid ${alignment}`} to="/login">Login</Link>
                    <Link className={`text-white d-grid ${alignment}`} to="/sign-up">Sign Up</Link>
                </div>

                <div className="mt-4">
                    <BoxTitle className="text-white mb-2">Resources:</BoxTitle>
                    <Link to="/privacy-policy" className={`text-white d-grid ${alignment}`}>Privacy Policy</Link>
                    <Link to="/terms-of-use" className={`text-white d-grid ${alignment}`}>Terms of Use</Link>
                </div>

            </div>

            <Paragraph 
            className="text-center m-0"
            color="white">Copyright: ©{year} Thank You Tip. All rights reserved.</Paragraph>

        </footer>
    )
}

export default Footer