// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFunctions, httpsCallable } from "firebase/functions"

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
  sendEmailVerification
} from "firebase/auth"

import {
  getFirestore,
  onSnapshot,
  query,
  where,
  orderBy,
  collection
} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbQdPPRPG8gsXf_-rOT3ndUl4aocGB6To",
  authDomain: "thank-you-tip.firebaseapp.com",
  projectId: "thank-you-tip",
  storageBucket: "thank-you-tip.appspot.com",
  messagingSenderId: "954811042832",
  appId: "1:954811042832:web:4007bc8e85191625adce95",
  measurementId: "G-HP8LMKGVX4"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// AUTH
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const auth = getAuth(app)

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// FUNCTIONS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const functions = getFunctions(app)

export {
  auth,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  httpsCallable,
  onSnapshot,
  collection,
  where,
  db,
  query,
  orderBy
}