import { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import "../src/css/styles.css"
import { authenticate } from "./store/actions/auth"

import publicRoute from "./navigation/publicRoute"
import privateRoute from "./navigation/privateRoute"

function App() {

  const dispatch = useDispatch()

  const { isLoggedIn, stripeStatus, status } = useSelector(state => state.auth)

  const reauthenticate = () => {
    dispatch(authenticate())
  }

  useEffect(() => {
    if (!isLoggedIn) reauthenticate()
  }, [isLoggedIn])

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // CREATE ROUTES
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const router = createBrowserRouter([
    isLoggedIn ? privateRoute({ stripeStatus, status }) : {},
    publicRoute()
  ])

  return (
    <RouterProvider router={router} />
  )
}

export default App
