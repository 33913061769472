import React from "react"
import { Container } from "react-bootstrap"

const PageContainer = ({ children }) => {

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <Container
            style={{ minHeight: "80vh" }}
            fluid className="mb-4 py-4 px-0">
            {children}
        </Container>
    )
}

export default PageContainer