import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isLoggedIn: false,
    name: "",
    email: "",
    phone: "",
    services: [],
    photo: {},
    newPhoto: {},
    acceptedTerms: false,
    stripeAccountId: "",
    customerId: "",
    uid: "",
    last5Feedbacks: [],
    totalEarned: 0,
    ageLimit: false,
    identityVerified: false,
    emailVerified: false,
    metadata: {},
    ratings: null,
    status: null,
    balanceAvailable: null,
    balancePending: null,
    stripeStatus: null
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {

        // UPDATE LOCALLY AFTER LOGGING IN USER
        loginUserSuccess(state, action) {
            state.isLoggedIn = true
            state.name = action.payload?.name
            state.email = action.payload?.email
            state.stripeStatus = action.payload?.stripeStatus
            state.phone = action.payload?.phone
            state.status = action.payload?.status
            state.services = action.payload?.services
            state.photo = action.payload?.photo
            state.acceptedTerms = true
            state.stripeAccountId = action.payload?.stripeAccountId
            state.customerId = action.payload?.customerId
            state.uid = action.payload?.uid
            state.last5Feedbacks = action.payload?.last5Feedbacks
            state.totalEarned = action.payload?.totalEarned
            state.ageLimit = true
            state.identityVerified = action.payload?.identityVerified
            state.emailVerified = action.payload?.emailVerified
            state.metadata = action.payload?.metadata
            state.ratings = action.payload?.ratings
        },

        updateProfile(state, action) {

            const prop = action.payload.prop
            const value = action.payload.value

            state[prop] = value

        },

        // RESET STATE
        userLogout() {
            return initialState
        },
    }
})

export const authActions = authSlice.actions

const authReducers = authSlice.reducer

export default authReducers