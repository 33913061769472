import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    service: null,
    punctuality: null,
    comment: ""
}

const feedbackSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {

        updateFeedback(state, action) {

            const prop = action.payload.prop
            const value = action.payload.value

            state[prop] = value

        }
    }
})

export const feedbackActions = feedbackSlice.actions

const feedbackReducers = feedbackSlice.reducer

export default feedbackReducers