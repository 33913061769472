import React, { Suspense } from "react"
import { useSelector } from "react-redux"
import { Outlet, useNavigation } from "react-router-dom"

import MainMenu from "../../components/nav/MainMenu"
import CustomSpinner from "../../components/ui/CustomSpinner"
import PageContainer from "../../components/containers/PageContainer"
import Footer from "../../components/nav/Footer"


const RootPage = () => {

    const navigation = useNavigation()

    const { isLoading } = useSelector(state => state.ui)

    const isLoadingClient = navigation.state === "loading"

    return (
        <Suspense fallback={<CustomSpinner />}>

            {(isLoadingClient || isLoading) && <CustomSpinner />}

            {(!isLoadingClient && !isLoading) && <main>

                <MainMenu />

                <PageContainer>
                    <Outlet />
                </PageContainer>

                <Footer />

            </main>}

        </Suspense>
    )
}

export default RootPage