import React from "react"

const ErrorPage = (props) => {

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// RENDER COMPONENT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

return (
<div>Ops</div>
)}

export default ErrorPage