import React from "react"
import { Button } from "react-bootstrap"

const CustomButton = (props) => {

    const { children, className } = props

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <Button
            {...props}
            style={{ minWidth: 225 }}
            className={`rounded-5 px-4 m-2 fw-semibold ${className}`}>
            {children}</Button>
    )
}

export default CustomButton