const getDataFromStorage = (key) => {

    const string = window.localStorage.getItem(key)

    const value = JSON.parse(string)

    return value

}

export default getDataFromStorage