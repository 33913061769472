import { lazy } from "react"
import { Navigate } from "react-router-dom"

import RootPage from "./pages/RootPage"
import ErrorPage from "./pages/ErrorPage"

const HomePage = lazy(() => import("../features/home/pages/HomePage"))
const LoginPage = lazy(() => import("../features/auth/pages/LoginPage"))
const SignupPage = lazy(() => import("../features/auth/pages/SignupPage"))
const TermsOfUse = lazy(() => import("../features/legal/pages/TermsOfUse"))
const ProfilePage = lazy(() => import("../features/profile/pages/ProfilePage"))
const ThankYouPage = lazy(() => import("../features/profile/pages/ThankYouPage"))
const PrivacyPolicy = lazy(() => import("../features/legal/pages/PrivacyPolicy"))

const publicRoute = () => {

    const children = [
        { path: "/", element: <HomePage /> },
        { path: "/login", element: <LoginPage /> },
        { path: "/signup", element: <SignupPage /> },
        { path: "/terms-of-use", element: <TermsOfUse /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/home", element: <HomePage /> },
        { path: "/thanks", element: <ThankYouPage /> },
        { path: "/profile/:uid", element: <ProfilePage /> },
        { path: "*", element: <Navigate to="/" replace /> },
    ]

    return {
        element: <RootPage />,
        errorElement: <ErrorPage />,
        children
    }
}

export default publicRoute