export const FIVE_MINUTES = 300000 // 300000 = 5 minutes
export const IMAGE_SIZE = 2048 // 2 MB
export const MINIMUM_TIP = 5
export const MAXIMUM_TIP = 1000
export const DOMAIN = "https://www.thankyoutip.com"
export const PAYOUT_SCHEDULE = "every Monday"
export const APP_FEE_PERCENTAGE = 5
export const APP_FEE_FIXED = 0.35
export const EMAIL_SUPPORT = "hello@thankyoutip.com"

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// SIZES
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const SCREEN_WIDTH = window.screen.width
export const SCREEN_HEIGHT = window.screen.height

export const IS_X_SMALL_DEVICE = SCREEN_WIDTH < 576
export const IS_SMALL_DEVICE = SCREEN_WIDTH >= 576
export const IS_MEDIUM_DEVICE = SCREEN_WIDTH >= 768
export const IS_LARGE_DEVICE = SCREEN_WIDTH >= 992
export const IS_XL_DEVICE = SCREEN_WIDTH >= 1200
export const IS_XXL_DEVICE = SCREEN_WIDTH >= 1400