import React from "react"
import { useDispatch } from "react-redux"

import { logUserOut } from "../../store/actions/auth"

import CustomButton from "./CustomButton"

const LogoutButton = ({ className }) => {

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logUserOut())
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <CustomButton
            className={className}
            variant="secondary" onClick={logoutHandler}>LOGOUT</CustomButton>
    )
}

export default LogoutButton