import { sendEmailVerification, auth } from "../../config/firebase"

const verifyEmail = async () => {

    try {
        await sendEmailVerification(auth.currentUser)
    } catch (error) {
        throw new Error(error)
    }
}

export default verifyEmail