import React from "react"
import { Link } from "react-router-dom"
import { Image } from "react-bootstrap"

const Logo = ({ full = false, white, className = "" }) => {

    const fullLogo = require("../../assets/logo_full.png")
    const logoWhite = require("../../assets/logo_white.png")
    const icon = require("../../assets/logo.png")

    const logo = white ? logoWhite : full ? fullLogo : icon
    const size = full ? 275 : 70

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <Link to="/home" className="d-flex justify-content-center">
            <Image width={size} src={logo} className={`my-2 ${className}`} />
        </Link>
    )
}

export default Logo