import { uiActions } from "../slices/uiSlice"
import { authActions } from "../slices/authSlice"

import {
    auth,
    signOut,
    signInWithEmailAndPassword
} from "../../config/firebase"

import verifyEmail from "../../utils/auth/verifyEmail"
import isSignupDataValid from "../../utils/auth/isSignupDataValid"
import callCallableFunction from "../../utils/functions/callCallableFunction"
import saveCredentialsToStorage from "../../utils/auth/saveCredentialsToStorage"
import getCredentialsFromStorage from "../../utils/auth/getCredentialsFromStorage"
import deleteCredentialsFromStorage from "../../utils/auth/deleteCredentialsFromStorage"

let userInfo

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// SIGNUP REQUEST
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const createAccount = (data) => {

    return async (dispatch) => {

        if (!isSignupDataValid(data)) return

        userInfo = data

        try {

            // SHOW SPINNER
            dispatch(uiActions.startSpinner("Creating your account..."))

            await callCallableFunction({
                functionName: "createUserProfile",
                args: { ...data }
            })

            dispatch(loginUser({ ...data, isNewUser: true }))

        } catch (error) {
            console.log('error :::::', error.message)
            alert(error.message) // CREATE A COMPONENT FOR ALERT!!!! <<<<<<<<<<<<<<<<<<
            dispatch(uiActions.stopSpinner())
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// LOGIN
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const loginUser = ({ email, password, isNewUser }) => {
    return async (dispatch) => {

        if (!email || !password) return alert("Incomplete Form.")

        try {

            // SHOW SPINNER
            dispatch(uiActions.startSpinner("Loading your account..."))

            // LOG IN USER
            const { user } = await signInWithEmailAndPassword(auth, email, password)

            // EXTRACT DATA FROM AUTHENTICATED USER
            const { stsTokenManager, uid, emailVerified } = user

            const expirationTime = stsTokenManager.expirationTime
            const authToken = await user.getIdToken()

            // NEW USER ALREADY HAS ALL NECESSARY DATA
            if (!isNewUser) {
                // GET USER PUBLIC AND PRIVATE DATA FROM FIRESTORE
                dispatch(getUserData({
                    uid,
                    email,
                    emailVerified
                }))
            }

            // SEND EMAIL VERIFICATION TO NEW USERS
            else {
                verifyEmail()
                dispatch(authActions.loginUserSuccess({ ...userInfo, uid }))
            }

            // SAVE DATA FOR RE-AUTHENTICATION WHEN USER REOPENS THE APP
            saveCredentialsToStorage({ email, password, expirationTime, authToken })

        } catch (error) {
            alert("Something went wrong. Please check your email and password.")
            dispatch(logUserOut())
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// GET USER DATA FROM FIRESTORE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const getUserData = ({ uid, email, emailVerified, isNewUser = false }) => {
    return async (dispatch) => {

        try {

            dispatch(uiActions.startSpinner())

            const userData = await callCallableFunction({
                functionName: "getCustomerData",
                args: { uid, email }
            })

            userData.emailVerified = emailVerified
            userData.email = userData?.email || email
            userData.uid = userData?.uid || uid

            dispatch(authActions.loginUserSuccess({ ...userData, isNewUser, emailVerified }))

        } catch (error) {

            alert(error.message)
            dispatch(logUserOut())

        }
    }
}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// LOGOUT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const logUserOut = () => {

    return async (dispatch) => {

        try {

            // SHOW SPINNER
            dispatch(uiActions.startSpinner("See you later!"))

            // DELETE SAVED DATA FROM COMPUTER'S MEMORY
            deleteCredentialsFromStorage()

            // LOG OUT FROM FIREBASE
            await signOut(auth)

            // UPDATE UI
            dispatch(authActions.userLogout())

        } catch (error) {
            alert(error.message)
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// AUTHENTICATE USER WITH DATA SAVED ON DEVICE
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const authenticate = () => {
    return async (dispatch) => {

        try {

            // GET USER'S CREDENTIALS FROM STORAGE
            const { email, password } = await getCredentialsFromStorage()

            // LOGIN USER IF THEIR CREDENTIAL ARE SAVED ON DEVICE
            if (!!email && !!password) await dispatch(loginUser({ email, password }))

        } catch (error) {
            dispatch(logUserOut())
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// DELETE USER'S ACCOUNT
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export const deleteAccount = ({ uid, photo }) => {
    return async (dispatch) => {

        try {

            const response = await callCallableFunction({
                functionName: "deleteUserAccount",
                args: { uid, photo }
            })

            alert(response)
            dispatch(logUserOut())

        } catch (error) {

        }
    }
}