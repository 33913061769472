const checkEmailValidity = (email) => {

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    let isRegexValid

    if (email === false || email?.length === 0) {
        isRegexValid = false
    }

    else {
        isRegexValid = emailRegex.test(email?.toLowerCase() || false)
    }

    if (!isRegexValid) {
        alert("Ops... Invalid email. Please enter a valid email!")
    }
    
    return isRegexValid
}

export default checkEmailValidity