const checkPhoneValidity = (phone, hideErrorMessage) => {

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

    let isRegexValid

    if (phone === false || phone?.length === 0) {
        isRegexValid = false
    }

    else {
        isRegexValid = phoneRegex.test(phone?.toLowerCase() || false)
    }

    if (!isRegexValid && !hideErrorMessage) {
        alert("Ops... Invalid phone. Please enter a valid phone!")
    }

    return isRegexValid
}

export default checkPhoneValidity