import { lazy } from "react"
import { Navigate } from "react-router-dom"

import RootPage from "./pages/RootPage"
import ErrorPage from "./pages/ErrorPage"

const AccountPage = lazy(() => import("../features/account/pages/AccountPage"))
const DashboardPage = lazy(() => import("../features/account/pages/DashboardPage"))
const OnboardingPage = lazy(() => import("../features/account/pages/OnboardingPage"))

const privateRoute = ({ status, stripeStatus }) => {

    const isActive = (status === "active" || status === "") && stripeStatus === "complete"

    const children = [
        {
            path: "/", element: !isActive ? <OnboardingPage /> :
                isActive ? <DashboardPage /> :
                    <AccountPage />
        },
        { path: "/account", element: !!isActive ? <AccountPage /> : <OnboardingPage /> },
        { path: "*", element: <Navigate to="/" replace /> },
    ]

    return {
        element: <RootPage />,
        errorElement: <ErrorPage />,
        children
    }
}

export default privateRoute