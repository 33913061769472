import { httpsCallable, functions } from "../../config/firebase"

const callCallableFunction = async ({ functionName, args, hideAlert }) => {

    try {

        const callableFunction = httpsCallable(functions, functionName)
        const { data } = await callableFunction({ ...args })

        return data

    } catch (error) {
        console.log(`callCallableFunction ${functionName}:::::`, error.message)

        if (!hideAlert) alert(error.message)

        throw error
    }
}

export default callCallableFunction