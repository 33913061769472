import React from "react"
import { Spinner } from "react-bootstrap"

const CustomSpinner = ({ message, small }) => {

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    if (small) {
        return <Spinner size="sm" variant="primary" />
    }

    return (
        <div className="d-flex justify-content-center align-items-center p-5 m-5">
            <Spinner variant="primary" />
            {message && <p>{message}</p>}
        </div>
    )
}

export default CustomSpinner