import React from "react"

import { EMAIL_SUPPORT } from "../../config/constants"

const EmailButton = ({ className = "", color = "" }) => {

    const sendEmail = () => {
        window.location.href = `mailto:${EMAIL_SUPPORT}`
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <a
            onClick={sendEmail}
            className={`text-${color || "primary"} fst-italic ${className}`}>
            {EMAIL_SUPPORT}
        </a>
    )
}

export default EmailButton