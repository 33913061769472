import React from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Nav, Navbar } from "react-bootstrap"

import Logo from "../ui/Logo"
import LogoutButton from "../buttons/LogoutButton"
import CustomButton from "../buttons/CustomButton"

const MainMenu = () => {

    const navigate = useNavigate()

    const { isLoggedIn } = useSelector(state => state.auth)

    const navigateToLogin = () => navigate("/login")

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <Navbar expand="lg" className="bg-primary py-0 px-5 mb-4">

            <Navbar.Brand to="/home" className="p-0"><Logo /></Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse
                className="d-lg-flex justify-content-end"
                id="responsive-navbar-nav">

                <Nav className="col-lg-8 justify-content-end">
                    <Link
                        className="text-white mx-4 my-2 fw-semibold text-center"
                        to="/home">HOME</Link>
                    {isLoggedIn && <Link
                        className="text-white mx-4 my-2 fw-semibold text-center"
                        to="/dashboard">DASHBOARD</Link>}
                    {isLoggedIn && <Link
                        className="text-white mx-4 my-2 fw-semibold text-center"
                        to="/account">ACCOUNT</Link>}

                </Nav>

                <div className="d-flex justify-content-center my-4 my-lg-0">

                    {isLoggedIn && <LogoutButton />}

                    {!isLoggedIn && <CustomButton
                        variant="secondary"
                        onClick={navigateToLogin}>SIGN IN</CustomButton>}
                </div>

            </Navbar.Collapse>
        </Navbar>
    )
}

export default MainMenu