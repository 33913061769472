import React from "react"
import { SCREEN_WIDTH } from "../../config/constants"

const BoxTitle = ({ children, className = "" }) => {

    const alignment = SCREEN_WIDTH <= 768 ? "text-center" : "text-start"

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <p className={`fs-4 mb-0 ${className} ${alignment}`}>{children}</p>
    )
}

export default BoxTitle