import checkPasswordValidity from "./checkPasswordValidity"
import checkEmailValidity from "./checkEmailValidity"
import checkPhoneValidity from "./checkPhoneValidity"

const isSignupDataValid = (data) => {

    const { phone, name, email, password, acceptedTerms, acceptedPrivacyPolicy } = data

    let title = "Incomplete Form."
    let message

    // VERIFY NAME
    if (name.length < 3) {
        message = "Please enter the your full name."
    }

    // VERIFY EMAIL VALIDITY
    else if (!checkEmailValidity(email)) return false

    // VERIFY PHONE
    else if (!checkPhoneValidity(phone)) return false

    // VERIFY PASSWORD MINIMUM REQUIREMENTS
    else if (!checkPasswordValidity(password)) return false

    else if (!acceptedTerms) {
        title = "Missing information."
        message = "You haven't accepted our Terms Of Use and Conditions."
    }
    else if (!acceptedPrivacyPolicy) {
        title = "Missing information."
        message = "You haven't accepted our Privacy Policy."
    }

    // SHOW MESSAGE IF SOME INPUT FIELD IS MISSING
    if (!!message) {
        alert(`${title} ${message}`)
        return false
    } else {
        return true
    }
}

export default isSignupDataValid