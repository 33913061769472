import React from "react"

import { SCREEN_WIDTH } from "../../config/constants"

const Paragraph = ({ children, className = "", color = "", mt = "", center }) => {

    const alignment = (SCREEN_WIDTH <= 768 || center) ? "text-center" : "text-start"

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // RENDER COMPONENT
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    return (
        <p className={`${mt || "mt-4"} text-${color} fw-light ${className} ${alignment}`}>
            {children}
        </p>
    )
}

export default Paragraph