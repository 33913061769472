const checkPasswordValidity = (password) => {

    let message = ""

    // CHECK PASSWORD'S LENGTH
    if (password.trim().length < 6) {
        message = "Your password should be at least 6 characters long."
        alert(`Invalid Form! ${message}`)
        return false
    }

    return true
}

export default checkPasswordValidity