import { createSlice } from "@reduxjs/toolkit"
import { authActions } from "./authSlice"

const initialState = {
    isLoading: false,
    spinnerMessage: "",
    errorMessage: "",
    notificationMessage: ""
}

const uiSlice = createSlice({
    initialState,
    name: "ui",
    reducers: {
        startSpinner: (state, action) => {
            state.isLoading = true
            state.spinnerMessage = action.payload
            state.errorMessage = ""
        },
        stopSpinner: () => {
            return initialState
        },
        saveErrorMessage: (state, action) => {
            state.errorMessage = action.payload
            state.notificationMessage = "" // ALWAYS CLEAN NOTIFICATION WHEN SAVING AN ERROR TO AVOID CONFLICT ON THE CUSTOM ALERT COMPONENT
            state.isLoading = false
        },
        saveNotification: (state, action) => {
            state.notificationMessage = action.payload
            state.errorMessage = ""  // ALWAYS CLEAN ERROR WHEN SAVING A NOTIFICATION TO AVOID CONFLICT ON THE CUSTOM ALERT COMPONENT
            state.isLoading = false
        },
        cleanMessages: (state) => {
            state.errorMessage = ""
            state.notificationMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authActions.loginUserSuccess, () => {
                return initialState
            })
            .addCase(authActions.userLogout, () => {
                return initialState
            })
    }
})

export const uiActions = uiSlice.actions

const uiReducers = uiSlice.reducer

export default uiReducers 